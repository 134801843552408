<template>
  <div class="services_section">
    <div class="cover_image_wrapper">
      <h2 class="cover_title">{{ currentLocale == 'bn' ? headerText.text_bn : headerText.text_en }}</h2>
      <img src="../../../../../assets/images/portal/cover-img.jpg" alt="" />
    </div>
    <div class="breadcrumb_wrapper">
      <b-container>
        <b-row>
          <b-col>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/portal/home">{{ $t('portal.home') }}</router-link></li>
                <li class="breadcrumb-item"><router-link to="/portal/all-services">{{ $t('portal.all_service') }}</router-link></li>
                <li class="breadcrumb-item"><a href="javascript:" @click="$router.go(-1)">{{ $t('portal.service_details') }}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{ $t('portal.accommodation_service_request') }} {{ $t('globalTrans.details') }}</li>
              </ol>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="service_details_wrapper">
      <b-container>
          <b-row>
              <b-col md="12">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{$t('portal.accommodation_service_request')}}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <b-button @click="pdfExport" :class="'btn btn_add_new mr-2'">
                        {{  $t('globalTrans.export_pdf') }}
                      </b-button>
                    </template>
                    <template v-slot:body>
                      <b-overlay :show="loader">
                        <b-row>
                          <b-col lg="12" sm="12" v-if="formData">
                            <div>
                              <p>{{ $t('portal.sd_1') }}</p>
                              <p style="margin: 0px;padding: 0px;">{{ $t('portal.sd_2') }}</p>
                              <p style="margin: 0px;padding: 0px;">{{ $t('portal.sd_3') }}</p>
                              <p>{{ $t('portal.sd_4') }}</p>
                              <p>{{ $t('portal.acc_rqst') }}</p>
                            </div>
                            <b-row>
                              <div class="w-100 text-left">
                                <div class="card-inside-title p-1">
                                  <h5 class="text-capitalize text-left p-2"
                                    style="color: white!important;font-size: 18px!important; background-color:#02813e!important;"> {{ $t('portal.applicant_information') }}</h5>
                                </div>
                              </div>
                            </b-row>
                            <div class="mt-2" v-if="formData.p_profession_type === 1 || formData.p_profession_type === 2">
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.applicant_type') + ' : ' + getProfessionType(formData.p_profession_type) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.applicant_name') + ' : ' + ($i18n.locale === 'bn' ? formData.applicant_name_bn : formData.applicant_name) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.applicant_designation') + ' : ' + ($i18n.locale === 'bn' ? formData.applicant_designation_bn : formData.applicant_designation) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('globalTrans.mobile') + ' : ' + ($i18n.locale === 'bn' ? '০' : '0') + $n(formData.mobile, { useGrouping: false }) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('globalTrans.email') + ' : ' + formData.email }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.name_institution') + ' : ' + ($i18n.locale === 'bn' ? formData.institution_name_bn : formData.institution_name) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.address_individuals_institution') + ' : ' + ($i18n.locale === 'bn' ? formData.address_bn : formData.address) }}</p>
                            </div>
                            <div class="mt-2" v-if="formData.p_profession_type === 3">
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.applicant_type') + ' : ' + getProfessionType(formData.p_profession_type) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('elearning_accommodation.profession_type') + ' : ' + getPProfessionType(formData.pp_profession_type) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.applicant_name') + ' : ' + ($i18n.locale === 'bn' ? formData.applicant_name_bn : formData.applicant_name) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.applicant_designation') + ' : ' + ($i18n.locale === 'bn' ? formData.applicant_designation_bn : formData.applicant_designation) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('globalTrans.mobile') + ' : ' + ($i18n.locale === 'bn' ? '০' : '0') + $n(formData.mobile, { useGrouping: false }) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('globalTrans.email') + ' : ' + formData.email }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.name_institution') + ' : ' + ($i18n.locale === 'bn' ? formData.institution_name_bn : formData.institution_name) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.address_individuals_institution') + ' : ' + ($i18n.locale === 'bn' ? formData.address_bn : formData.address) }}</p>
                            </div>
                            <b-row>
                              <div class="w-100 text-left">
                                <div class="card-inside-title p-1">
                                  <h5 class="text-capitalize text-left bg-light p-2 badge-light"
                                  style="color: white!important;font-size: 18px!important; background-color:#02813e!important;"> {{ $t('portal.room_use_info') }}</h5>
                                </div>
                              </div>
                            </b-row>
                            <div class="mt-2">
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.use_purpose') + ' : ' + getUsePurpose(formData.use_purpose) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('globalTrans.title') + ' : ' + ($i18n.locale === 'bn' ? formData.training_title_bn : formData.training_title) }}</p>
                              <p style="margin: 2px;padding: 2px;"><slot>{{ $t('elearning_accommodation.check_in') + ' : ' }}</slot> {{ formData.start_date | dateFormat }}</p>
                              <p style="margin: 2px;padding: 2px;"><slot>{{ $t('elearning_accommodation.check_out') + ' : ' }}</slot> {{ formData.end_date | dateFormat }}</p>
                            </div>
                            <b-row>
                              <div class="w-100 text-left">
                                <div class="card-inside-title p-1">
                                  <h5 class="text-capitalize text-left bg-light p-2 badge-light"
                                  style="color: white!important;font-size: 18px!important; background-color:#02813e!important;"> {{ $t('portal.user_information') }}</h5>
                                </div>
                              </div>
                            </b-row>
                            <b-table-simple bordered v-if="formData.p_profession_type === 1">
                              <b-tr>
                                  <b-th class="text-right" style="width:20%">{{ $t('portal.grade_from') }}</b-th>
                                  <b-th class="text-right" style="width:20%">{{ $t('portal.grade_to') }}</b-th>
                                  <b-th class="text-right" style="width:20%">{{ $t('portal.male_trainee') }}</b-th>
                                  <b-th class="text-right" style="width:20%">{{ $t('portal.female_trainee') }}</b-th>
                                  <b-th class="text-right" style="width:20%">{{ $t('portal.total_trainee') }}</b-th>
                              </b-tr>
                              <b-tr v-for="(item,index) in formData.user_details" :key="index">
                                  <b-td class="text-right">{{ getGradeForm(item.grade_from) }}</b-td>
                                  <b-td class="text-right">{{ getGradeTo(item.grade_to) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.male_trainee) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.female_trainee) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.total_trainee) }}</b-td>
                              </b-tr>
                            </b-table-simple>
                            <b-table-simple bordered v-if="formData.p_profession_type === 3 && formData.pp_profession_type === 1">
                              <b-tr>
                                  <b-th class="text-right" style="width:20%">{{ $t('portal.grade_from') }}</b-th>
                                  <b-th class="text-right" style="width:20%">{{ $t('portal.grade_to') }}</b-th>
                                  <b-th class="text-right" style="width:20%">{{ $t('portal.male_trainee') }}</b-th>
                                  <b-th class="text-right" style="width:20%">{{ $t('portal.female_trainee') }}</b-th>
                                  <b-th class="text-right" style="width:20%">{{ $t('portal.total_trainee') }}</b-th>
                              </b-tr>
                              <b-tr v-for="(item,index) in formData.user_details" :key="index">
                                  <b-td class="text-right">{{ getGradeForm(item.grade_from) }}</b-td>
                                  <b-td class="text-right">{{ getGradeTo(item.grade_to) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.male_trainee) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.female_trainee) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.total_trainee) }}</b-td>
                              </b-tr>
                            </b-table-simple>
                            <b-table-simple bordered v-if="formData.p_profession_type === 2">
                              <b-tr>
                                  <b-th class="text-right" style="width:20%">{{ $t('portal.officer_type') }}</b-th>
                                  <b-th class="text-right" style="width:20%">{{ $t('portal.male_trainee') }}</b-th>
                                  <b-th class="text-right" style="width:20%">{{ $t('portal.female_trainee') }}</b-th>
                                  <b-th class="text-right" style="width:20%">{{ $t('portal.total_trainee') }}</b-th>
                              </b-tr>
                              <b-tr v-for="(item,index) in formData.user_details" :key="index">
                                  <b-td class="text-right">{{ getOfficerType(item.officer_type) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.male_trainee) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.female_trainee) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.total_trainee) }}</b-td>
                              </b-tr>
                            </b-table-simple>
                            <b-table-simple bordered v-if="formData.p_profession_type === 3 && formData.pp_profession_type === 2">
                              <b-tr>
                                  <b-th class="text-right" style="width:20%">{{ $t('portal.officer_type') }}</b-th>
                                  <b-th class="text-right" style="width:20%">{{ $t('portal.male_trainee') }}</b-th>
                                  <b-th class="text-right" style="width:20%">{{ $t('portal.female_trainee') }}</b-th>
                                  <b-th class="text-right" style="width:20%">{{ $t('portal.total_trainee') }}</b-th>
                              </b-tr>
                              <b-tr v-for="(item,index) in formData.user_details" :key="index">
                                  <b-td class="text-right">{{ getOfficerType(item.officer_type) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.male_trainee) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.female_trainee) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.total_trainee) }}</b-td>
                              </b-tr>
                            </b-table-simple>
                            <b-row>
                              <div class="w-100 text-left">
                                <div class="card-inside-title p-1">
                                  <h5 class="text-capitalize text-left bg-light p-2 badge-light"
                                  style="color: white!important;font-size: 18px!important; background-color:#02813e!important;"> {{ $t('portal.room_information') }}</h5>
                                </div>
                              </div>
                            </b-row>
                            <b-table-simple bordered v-if="formData.p_profession_type === 1">
                              <b-tr>
                                  <b-th class="text-right" style="width:15%">{{ $t('portal.grade_from') }}</b-th>
                                  <b-th class="text-right" style="width:15%">{{ $t('portal.grade_to') }}</b-th>
                                  <b-th class="text-right" style="width:15%">{{ $t('portal.room_type') }}</b-th>
                                  <b-th class="text-right" style="width:15%">{{ $t('elearning_config.room_number') }}</b-th>
                                  <b-th class="text-right" style="width:20%">{{ $t('elearning_config.room_rent') }}</b-th>
                                  <b-th class="text-right" style="width:15%">{{ $t('elearning_config.total_rent') }}</b-th>
                              </b-tr>
                              <b-tr v-for="(item,index) in formData.room_details" :key="index">
                                  <b-td class="text-right">{{ getGradeForm(item.grade_from) }}</b-td>
                                  <b-td class="text-right">{{ getGradeTo(item.grade_to) }}</b-td>
                                  <b-td class="text-right">{{ getRoomType(item.room_type_id) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.room_number) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.room_rent) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.total_rent) }}</b-td>
                              </b-tr>
                            </b-table-simple>
                            <b-table-simple bordered v-if="formData.p_profession_type === 3 && formData.p_profession_type === 1">
                              <b-tr>
                                  <b-th class="text-right" style="width:15%">{{ $t('portal.grade_from') }}</b-th>
                                  <b-th class="text-right" style="width:15%">{{ $t('portal.grade_to') }}</b-th>
                                  <b-th class="text-right" style="width:15%">{{ $t('portal.room_type') }}</b-th>
                                  <b-th class="text-right" style="width:15%">{{ $t('elearning_config.room_number') }}</b-th>
                                  <b-th class="text-right" style="width:20%">{{ $t('elearning_config.room_rent') }}</b-th>
                                  <b-th class="text-right" style="width:15%">{{ $t('elearning_config.total_rent') }}</b-th>
                              </b-tr>
                              <b-tr v-for="(item,index) in formData.room_details" :key="index">
                                  <b-td class="text-right">{{ getGradeForm(item.grade_from) }}</b-td>
                                  <b-td class="text-right">{{ getGradeTo(item.grade_to) }}</b-td>
                                  <b-td class="text-right">{{ getRoomType(item.room_type_id) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.room_number) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.room_rent) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.total_rent) }}</b-td>
                              </b-tr>
                            </b-table-simple>
                            <b-table-simple bordered v-if="formData.p_profession_type === 2">
                              <b-tr>
                                  <b-th class="text-right" style="width:20%">{{ $t('portal.officer_type') }}</b-th>
                                  <b-th class="text-right" style="width:15%">{{ $t('portal.room_type') }}</b-th>
                                  <b-th class="text-right" style="width:15%">{{ $t('elearning_config.room_number') }}</b-th>
                                  <b-th class="text-right" style="width:20%">{{ $t('elearning_config.room_rent') }}</b-th>
                                  <b-th class="text-right" style="width:15%">{{ $t('elearning_config.total_rent') }}</b-th>
                              </b-tr>
                              <b-tr v-for="(item,index) in formData.room_details" :key="index">
                                  <b-td class="text-right">{{ getOfficerType(item.officer_type) }}</b-td>
                                  <b-td class="text-right">{{ getRoomType(item.room_type_id) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.room_number) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.room_rent) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.total_rent) }}</b-td>
                              </b-tr>
                            </b-table-simple>
                            <b-table-simple bordered v-if="formData.p_profession_type === 3 && formData.pp_profession_type === 2">
                              <b-tr>
                                  <b-th class="text-right" style="width:20%">{{ $t('portal.officer_type') }}</b-th>
                                  <b-th class="text-right" style="width:15%">{{ $t('portal.room_type') }}</b-th>
                                  <b-th class="text-right" style="width:15%">{{ $t('elearning_config.room_number') }}</b-th>
                                  <b-th class="text-right" style="width:20%">{{ $t('elearning_config.room_rent') }}</b-th>
                                  <b-th class="text-right" style="width:15%">{{ $t('elearning_config.total_rent') }}</b-th>
                              </b-tr>
                              <b-tr v-for="(item,index) in formData.room_details" :key="index">
                                  <b-td class="text-right">{{ getOfficerType(item.officer_type) }}</b-td>
                                  <b-td class="text-right">{{ getRoomType(item.room_type_id) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.room_number) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.room_rent) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.total_rent) }}</b-td>
                              </b-tr>
                            </b-table-simple>
                            <b-row>
                              <b-col md="4"></b-col>
                              <b-col md="4"></b-col>
                              <b-col md="4">
                                <p style="margin-bottom: 0px;padding-bottom: 0px; padding-top: 30px;" class="text-center">{{ $t('portal.sd_6') }}</p>
                                <p style="margin: 0px;padding: 0px;" class="text-center">{{ $i18n.locale === 'bn' ? formData.applicant_name_bn : formData.applicant_name }}</p>
                                <p style="margin: 0px;padding: 0px;" class="text-center">{{ $i18n.locale === 'bn' ? formData.applicant_designation_bn : formData.applicant_designation }}</p>
                                <p style="margin: 0px;padding: 0px;" class="text-center">{{ $i18n.locale === 'bn' ? formData.address_bn : formData.address }}</p>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-overlay>
                    </template>
                  </iq-card>
              </b-col>
          </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import ExportPdf from './export_pdf_Accommodation_Service_Request_details'
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'
import flatpickr from 'flatpickr'

export default {
    mixins: [ModalBaseMasterList],
    components: {
    },
    data () {
      return {
        headerText: { text_en: '', text_bn: '' },
        loader: false,
        formData: {}
      }
    },
    watch: {
    },
    computed: {
      usePurposeList: function () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Training' : 'প্রশিক্ষণ', text_en: 'Training', text_bn: 'প্রশিক্ষণ' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Workshop' : 'কর্মশালা', text_en: 'Workshop', text_bn: 'কর্মশালা' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Seminer' : 'সেমিনার', text_en: 'Seminer', text_bn: 'সেমিনার' },
          { value: 4, text: this.$i18n.locale === 'en' ? 'Meeting' : 'মিটিং', text_en: 'Meeting', text_bn: 'মিটিং' },
          { value: 4, text: this.$i18n.locale === 'en' ? 'Personal' : 'ব্যক্তিগত', text_en: 'Personal', text_bn: 'ব্যক্তিগত' }
        ]
      },
      professionTypeList () {
        const professionTypeList = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Government Institutions' : 'সরকারি প্রতিষ্ঠান', text_en: 'Government Institutions', text_bn: 'সরকারি প্রতিষ্ঠান' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Private Institutions' : 'বেসরকারি প্রতিষ্ঠান', text_en: 'Private Institutions', text_bn: 'বেসরকারি প্রতিষ্ঠান' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Person' : 'ব্যক্তি', text_en: 'Person', text_bn: 'ব্যক্তি' }
        ]
        return professionTypeList
      },
      officerTypeList: function () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Senior Officer' : 'ঊর্ধ্বতন কর্মকর্তা', text_en: 'Senior Officer', text_bn: 'ঊর্ধ্বতন কর্মকর্তা' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Mid-level Officer' : 'মিড লেভেল কর্মকর্তা', text_en: 'Mid-level Officer', text_bn: 'মিড লেভেল কর্মকর্তা' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Junior Officer' : 'জুনিয়র কর্মকর্তা', text_en: 'Junior Officer', text_bn: 'জুনিয়র কর্মকর্তা' }
        ]
      },
      personProfessionTypeList () {
        const professionTypeList = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Government' : 'সরকারি', text_en: 'Government', text_bn: 'সরকারি' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Non Government' : 'বেসরকারি', text_en: 'Non Government', text_bn: 'বেসরকারি' }
        ]
        return professionTypeList
      },
      ...mapGetters({
        authUser: 'Auth/authUser'
      }),
      currentLocale () {
        return this.$i18n.locale
      },
      gradeList: function () {
        return this.$store.state.Portal.commonObj.gradeList.filter(item => item.status === 0)
      }
    },
    async created () {
      this.loadData()
    },
    mounted () {
      core.index()
      flatpickr('.fromDate', {})
    },
    methods: {
      getUsePurpose (id) {
        const trainingType = this.usePurposeList.find(item => item.value === parseInt(id))
        if (this.$i18n.locale === 'bn') {
            return trainingType !== undefined ? trainingType.text_bn : ''
        } else {
            return trainingType !== undefined ? trainingType.text_en : ''
        }
      },
      getProfessionType (id) {
        const trainingType = this.professionTypeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return trainingType !== undefined ? trainingType.text_bn : ''
        } else {
            return trainingType !== undefined ? trainingType.text_en : ''
        }
      },
      getPProfessionType (id) {
        const trainingType = this.personProfessionTypeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return trainingType !== undefined ? trainingType.text_bn : ''
        } else {
            return trainingType !== undefined ? trainingType.text_en : ''
        }
      },
      getGradeForm (id) {
        const trainingType = this.$store.state.Portal.commonObj.gradeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return trainingType !== undefined ? trainingType.text_bn : ''
        } else {
            return trainingType !== undefined ? trainingType.text_en : ''
        }
      },
      getGradeTo (id) {
        const trainingType = this.$store.state.Portal.commonObj.gradeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return trainingType !== undefined ? trainingType.text_bn : ''
        } else {
            return trainingType !== undefined ? trainingType.text_en : ''
        }
      },
      getOfficerType (id) {
        const trainingType = this.officerTypeList.find(item => item.value === id)
          if (this.$i18n.locale === 'bn') {
              return trainingType !== undefined ? trainingType.text_bn : ''
          } else {
              return trainingType !== undefined ? trainingType.text_en : ''
          }
      },
      getRoomType (id) {
        const trainingType = this.$store.state.Portal.trainObj.roomTypeList.find(item => item.value === id)
          if (this.$i18n.locale === 'bn') {
              return trainingType !== undefined ? trainingType.text_bn : ''
          } else {
              return trainingType !== undefined ? trainingType.text_en : ''
          }
      },
      async loadData () {
        this.loader = true
        RestApi.getData(trainingElearningServiceBaseUrl, `/portal/accommodation-service-request/show/${this.$route.query.id}`).then(response => {
          if (response.success) {
            this.formData = response.data
            this.loader = false
          } else {
            this.formData = {}
            this.loader = false
          }
        })
        this.loader = false
      },
      pdfExport () {
        const reportTitle = this.$t('portal.accommodation_service_request')
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.formData, this)
      }
    }
}
</script>
<style>
  .alert .close {
    color: red !important;
  }
  #form fieldset {
    border: 1px solid #8f7c7c!important;
    float: left!important;
    margin-bottom: 25px!important;
    padding: 10px 0!important;
    width: 99.5%!important;
  }
  #form legend {
    background: #02813e none repeat scroll 0 0!important;
    border: 1px solid #e5e5e5!important;
    color: #fff!important;
    font-size: 13px!important;
    font-weight: 700!important;
    margin-bottom: 0!important;
    margin-left: 16px!important;
    padding: 6px 10px!important;
    width: 31%!important;
    border-radius: 0!important;
  }
  .hidden_header {
    display: none
  }

  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }

  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }

  .reportTitle {
    font-weight: bold;
    border: 1px solid;
    padding: 8px 15px 8px 15px;
    border-radius: 11px;
  }

  .titleDiv {
    margin-top: 2rem;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .report-name {
    font-weight: bold !important;
    text-transform: uppercase;
  }

  .my-btn {
    padding: 2px !important;
  }
</style>
